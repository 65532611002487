



























































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { RateCard, Rate } from '@/models/index'
import { Form } from 'element-ui'
import { IValidationError } from 'spraypaint/lib-esm/validation-errors'

@Component({
  name: 'RatesTable',
  components: {
  }
})
export default class extends Vue {
  @Prop({ required: true }) private rateCard!: RateCard
  @Prop({ required: true }) private loading!: boolean

  private tempData = new Rate({ rateCard: this.rateCard })

  private rules = {
    amount: [{ required: true, message: 'amount is required', trigger: 'blur' }],
    requirement: [{ required: true, message: 'requirement is required', trigger: 'blur' }]
  }

  private requirementOperatorOptions = [
    { name: '<', value: 'lt' },
    { name: '<=', value: 'lt_or_eq' },
    { name: '>', value: 'gt' },
    { name: '>=', value: 'gt_or_eq' }
  ]

  private dialogFormVisible = false
  private dialogStatus = ''
  private textMap = {
    update: 'Edit',
    create: 'Create'
  }

  private resetTempData() {
    this.tempData = new Rate({ rateCard: this.rateCard })
  }

  private handleCreate() {
    this.resetTempData()
    this.dialogStatus = 'create'
    this.dialogFormVisible = true
    this.$nextTick(() => {
      (this.$refs.dataForm as Form).clearValidate()
    })
  }

  private handleUpdate(row: any) {
    this.tempData = row
    this.dialogStatus = 'update'
    this.dialogFormVisible = true
    this.$nextTick(() => {
      (this.$refs.dataForm as Form).clearValidate()
    })
  }

  private async handleDelete(data: Rate, index: number) {
    await data.destroy()

    if (Object.keys(data.errors).length > 0) {
      this.printErrors(data.errors)
    } else {
      this.$emit('delete', data)
      this.$notify({
        title: 'Success',
        message: 'Delete Successfully',
        type: 'success',
        duration: 2000
      })
    }
  }

  private createOrUpdate() {
    (this.$refs.dataForm as Form).validate(async(valid) => {
      if (valid) {
        const data = this.tempData

        const with_ = this.dialogStatus === 'create' ? { with: 'rateCard.id' } : {}
        await data.save(with_)

        if (Object.keys(data.errors).length > 0) {
          this.printErrors(data.errors)
        } else {
          if (this.dialogStatus === 'create') {
            this.$emit('create', data)
            this.$notify({
              title: 'Rate Created',
              message: 'Rate successfully created',
              type: 'success',
              duration: 2000
            })
          } else {
            this.$emit('update', data)
            this.$notify({
              title: 'Rate Updated',
              message: 'Rate successfully updated',
              type: 'success',
              duration: 2000
            })
          }
        }

        this.dialogFormVisible = false
      }
    })
  }

  private printErrors(errors: any) {
    const e: (IValidationError<Rate> | undefined)[] = Object.values(errors)
    for (const error of e) {
      if (error) {
        this.$notify({
          title: error.title,
          message: error.fullMessage,
          type: 'error',
          duration: 5000
        })
      }
    }
  }
}

