









































































































































import { Component, Vue } from 'vue-property-decorator'
import Pagination from '@/components/Pagination/index.vue'

import {GiftCard, PurchaseInfo, Seller, Wholesaler} from '@/models/index'
import { IValidationError } from 'spraypaint/lib-esm/validation-errors'

@Component({
  name: 'RatesList',
  components: {
    Pagination,
    DialogCreatePurchaseInfo: () => import('./DialogCreatePurchaseInfo.vue')
  }
})
export default class extends Vue {
  private tableKey = 0
  private list: PurchaseInfo[] = []
  private total = 0
  private listLoading = true
  private listQuery = {
    page: 1,
    limit: 20,
    name: undefined,
    sellerId: '',
    wholesalerId: '',
    giftCardId: '',
  }

  private sellers: Seller[] = []
  private sellerListLoading = true

  private wholesalers: Wholesaler[] = []
  private wholesalerLoading = true

  private giftcards: GiftCard[] = []
  private giftcardsLoading = true

  private showDialogPurchaseInfo = false

  async created() {
    await this.getSellers()
    await this.getWholesalers()
    await this.getGiftCards()
    await this.getList()
  }

  get loading() {
    return this.listLoading || this.sellerListLoading
  }

  private async getList() {
    this.listLoading = true
    const { data, meta } =
      await PurchaseInfo
        .where({
          buyerId: 'null',
          sellerId: this.listQuery.sellerId,
          wholesalerId: this.listQuery.wholesalerId,
          giftCardId: this.listQuery.giftCardId
        })
        .includes(['seller', 'wholesaler', 'giftCard'])
        .page(this.listQuery.page)
        .per(this.listQuery.limit)
        .stats({ total: 'count' }).all()

    this.list = data
    this.total = meta.stats.total.count
    this.listLoading = false
  }

  private async getSellers() {
    this.sellerListLoading = true
    const { data } = await Seller.per(999).page(1).all()

    this.sellers = data
    this.sellerListLoading = false
  }

  private async getWholesalers() {
    this.wholesalerLoading = true
    const { data } = await Wholesaler.per(999).page(1).all()

    this.wholesalers = data
    this.listQuery.wholesalerId = this.wholesalers[0].id
    this.wholesalerLoading = false
  }

  private async getGiftCards() {
    this.giftcardsLoading = true
    const { data } = await GiftCard.per(999).page(1).order({ name: 'asc' }).all()

    this.giftcards = data
    this.giftcardsLoading = false
  }

  private handleFilter() {
    this.listQuery.page = 1
    this.getList()
  }

  private async handleDelete(data: PurchaseInfo, index: number) {
    await data.destroy()

    if (Object.keys(data.errors).length > 0) {
      this.printErrors(data.errors)
    } else {
      this.$notify({
        title: 'Success',
        message: 'Delete Successfully',
        type: 'success',
        duration: 2000
      })
      this.list.splice(index, 1)
      this.total -= 1
    }
  }

  private printErrors(errors: any) {
    const e: (IValidationError<PurchaseInfo> | undefined)[] = Object.values(errors)
    for (const error of e) {
      if (error) {
        this.$notify({
          title: error.title,
          message: error.fullMessage,
          type: 'error',
          duration: 5000
        })
      }
    }
  }
}
