export const apiClientOptions: any = [
  {
    name: 'No Client',
    value: 'no_client'
  },
  {
    name: 'Internal Client',
    value: 'internal_client'
  },
  {
    name: 'Amazon Client',
    value: 'amazon_client'
  },
  {
    name: 'Numen Client',
    value: 'numen_client'
  },
  {
    name: 'Oneprepaid Client',
    value: 'oneprepaid_client'
  },
  {
    name: 'Buybox Client',
    value: 'buybox_client'
  },
  {
    name: 'Decathlon Client',
    value: 'decathlon_client'
  },
  {
    name: 'EGifter Client',
    value: 'egifter_client'
  },
  {
    name: 'Wegift Client',
    value: 'wegift_client'
  },
  {
    name: 'Zalando Client',
    value: 'zalando_client'
  },
  {
    name: 'SELP Client',
    value: 'selp_client'
  },
  {
    name: 'Ogloba Client',
    value: 'ogloba_client'
  },
  {
    name: 'Diggecard Client',
    value: 'diggecard_client'
  },
  {
    name: 'Decathlon V2 Client',
    value: 'decathlon_v2_client'
  },
  {
    name: 'Easy2Play Client',
    value: 'easy2play_client'
  },
  {
    name: 'Numen V2 Client',
    value: 'numen_v2_client'
  },
  {
    name: 'Carrefour Client',
    value: 'carrefour_client'
  },
  {
    name: 'Ethikdo Client',
    value: 'ethikdo_client'
  },
  {
    name: 'Intersolve Client',
    value: 'intersolve_client'
  },
  {
    name: 'RLC GCS Client',
    value: 'rlcgcs_client'
  },
  {
    name: 'Runa Client',
    value: 'runa_client'
  }
]

export const invoicePerOptions: any = [
  {
    name: 'Invoice per order',
    value: 'invoice_per_order'
  },
  {
    name: 'Invoice monthly',
    value: 'invoice_per_month'
  },
]

export const paymentModeOptions: any = [
  {
    name: 'Prepaid',
    value: 'prepaid'
  },
  {
    name: 'Differed',
    value: 'differed'
  },
]

export const offsetOptions: any = [
  {
    name: 'No offset',
    value: 'no_offset'
  },
  {
    name: 'Partial offset',
    value: 'partial_offset'
  },
  {
    name: 'Complete offset',
    value: 'complete_offset'
  }
]

export const partialModeOffsetOptions: any = [
  {
    name: 'Instant partial offset',
    value: 'instant_partial_offset'
  },
  {
    name: 'Differed partial offset',
    value: 'differed_partial_offset'
  },
  {
    name: 'Manual partial offset',
    value: 'manual_partial_offset'
  }
]

export const payVatOptions: any = [
  {
    name: 'Vat paid',
    value: 'vat_paid'
  },
  {
    name: 'Vat not paid',
    value: 'vat_not_paid'
  },
  {
    name: 'Vat irrelevant',
    value: 'vat_irrelevant'
  }
]

export const vatPaybackOptions: any = [
  {
    name: 'No vat payback',
    value: 'no_vat_payback'
  },
  {
    name: 'Bank transfer vat payback',
    value: 'bank_transfer_vat_payback'
  },
  {
    name: 'Prepaid account vat payback',
    value: 'prepaid_account_vat_payback'
  },
  {
    name: 'In commission vat payback',
    value: 'in_commission_vat_payback'
  }
]
