

























































































import { Component, Vue } from 'vue-property-decorator'
import { Form } from 'element-ui'
import { RateCard, Rate, Manageable, Seller, Wholesaler } from '@/models/index'
import { IValidationError } from 'spraypaint/lib-esm/validation-errors'

@Component({
  name: 'RateCardsEdit',
  components: {
    RateTable: () => import('./components/rates-table.vue')
  }
})
export default class extends Vue {
  private rateCard: RateCard = new RateCard({ manageable: new Manageable() })
  private rateCardLoading = false
  private manageableList: any = []
  private manageableLoading = false

  private rules = {
    name: [{ required: true, message: 'Name is required', trigger: 'blur' }],
    requirement: [{ required: true, message: 'Requirement is required', trigger: 'change' }]
  }

  private requirementOptions = [
    { name: 'No rules', value: 'no_rule' },
    { name: 'Volume', value: 'volume' },
    { name: 'Seller Rate', value: 'seller_rate' }
  ]

  created() {
    if (!this.isCreate) this.getRateCard()
    this.getManageableList()
  }

  get isCreate() {
    return this.$route.name === 'RateCardCreate'
  }

  private async getRateCard() {
    this.rateCardLoading = true
    const { data } = await RateCard.includes(['manageable', 'rates']).find(this.$route.params.id)

    this.rateCard = data
    this.rateCardLoading = false
  }

  private async getManageableList() {
    this.manageableLoading = true
    const { data: sellers } = await Seller.per(999).page(1).all()
    const { data: wholesalers } = await Wholesaler.all()

    this.manageableList = [{
      label: 'Wholesaler',
      options: [...wholesalers]
    },
    {
      label: 'Sellers',
      options: [...sellers]
    }]
    if (this.isCreate) this.rateCard.manageable = this.manageableList[0].options[0].dup()
    this.manageableLoading = false
  }

  private handleSelectManageable(id: string) {
    const manageable: any = this.manageableList.map((e: any) => e.options).flat().find((e: any) => e.id === id)
    this.rateCard.manageable = manageable?.dup()
  }

  private handleCreateRate(rate: Rate) {
    this.rateCard.rates.push(rate)
  }

  private handleUpdateRate(rate: Rate) {
    const index = this.rateCard.rates.findIndex(e => e.id === rate.id)
    this.rateCard.rates.splice(index, 1, rate)
  }

  private handleDeleteRate(rate: Rate) {
    const index = this.rateCard.rates.findIndex(e => e.id === rate.id)
    this.rateCard.rates.splice(index, 1)
  }

  private async createOrUpdate() {
    (this.$refs.dataForm as Form).validate(async(valid: any) => {
      if (valid) {
        const data = this.rateCard

        await data.save({ with: 'manageable' })

        if (Object.keys(data.errors).length > 0) {
          this.printErrors(data.errors)
        } else {
          if (this.isCreate) {
            this.$notify({
              title: 'Rate Card created',
              message: 'Rate Card Card successfully created',
              type: 'success',
              duration: 2000
            })
            this.$router.replace({ name: 'RateCardEdit', params: { id: data.id } })
          } else {
            this.$notify({
              title: 'Rate Card updated',
              message: 'Rate Card successfully updated',
              type: 'success',
              duration: 2000
            })
          }
        }
      }
    })
  }

  private printErrors(errors: any) {
    const e: (IValidationError<RateCard> | undefined)[] = Object.values(errors)
    for (const error of e) {
      if (error) {
        this.$notify({
          title: error.title,
          message: error.fullMessage,
          type: 'error',
          duration: 5000
        })
      }
    }
  }
}

