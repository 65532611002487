



















































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { Form } from 'element-ui'
import { exportJson2Excel } from '@/utils/excel'
import { formatJson } from '@/utils'
import Pagination from '@/components/Pagination/index.vue'

import { User, Buyer, Wholesaler, Seller, Caretaker, Manageable } from '@/models/index'
import { IValidationError } from 'spraypaint/lib-esm/validation-errors'

@Component({
  name: 'ComplexTable',
  components: {
    Pagination
  }
})
export default class extends Vue {
  private tableKey = 0
  private list: User[] = []
  private total = 0
  private listLoading = true
  private listQuery = {
    page: 1,
    limit: 20,
    importance: undefined,
    email: undefined,
    type: undefined,
    sort: '+id',
    manageableId: undefined
  }

  private manageableList: any[] = []
  private searchManageable = ''

  private dialogFormVisible = false
  private dialogStatus = ''
  private textMap = {
    update: 'Edit',
    create: 'Create'
  }

  private rules = {
    type: [{ required: true, message: 'type is required', trigger: 'change' }],
    timestamp: [{ required: true, message: 'timestamp is required', trigger: 'change' }],
    title: [{ required: true, message: 'title is required', trigger: 'blur' }]
  }

  private downloadLoading = false
  private tempData = new User({ manageable: new Manageable({ id: '' }) })

  created() {
    this.getList()
    this.getManageableList()
  }

  private async getList() {
    this.listLoading = true
    const { data, meta } =
      await User
        .where({
          email: { prefix: this.listQuery.email },
          manageableId: this.listQuery.manageableId
        })
        .includes('manageable')
        .stats({ total: 'count' })
        .per(this.listQuery.limit)
        .page(this.listQuery.page)
        .all()

    this.list = data
    this.total = meta.stats.total.count
    this.listLoading = false
  }

  private async getManageableList() {
    const { data: buyers } = await Buyer.per(999).page(1).all()
    const { data: wholesalers } = await Wholesaler.per(999).page(1).all()
    const { data: sellers } = await Seller.per(999).page(1).all()
    const { data: caretakers } = await Caretaker.per(999).page(1).all()

    this.manageableList.push(...buyers, ...wholesalers, ...sellers, ...caretakers)
  }

  private handleFilter() {
    this.listQuery.page = 1
    this.getList()
  }

  private handleModifyStatus(row: any, status: string) {
    this.$message({
      message: '操作成功',
      type: 'success'
    })
    row.status = status
  }

  private resetTempUserData() {
    this.tempData = new User({
      manageable: this.manageableList[0].dup()
    })
  }

  private handleCreate() {
    this.resetTempUserData()
    this.dialogStatus = 'create'
    this.dialogFormVisible = true
    this.$nextTick(() => {
      (this.$refs.dataForm as Form).clearValidate()
    })
  }

  private async createData() {
    (this.$refs.dataForm as Form).validate(async(valid) => {
      if (valid) {
        const userData = this.tempData

        await userData.save({ with: 'manageable.id' })

        if (Object.keys(userData.errors).length > 0) {
          this.printErrors(userData.errors)
        } else {
          this.list.unshift(userData)
          this.total += 1
          this.dialogFormVisible = false
          this.$notify({
            title: 'User created',
            message: 'User successfully created',
            type: 'success',
            duration: 2000
          })
        }
      }
    })
  }

  private handleUpdate(row: any) {
    this.tempData = row
    this.dialogStatus = 'update'
    this.dialogFormVisible = true
    this.$nextTick(() => {
      (this.$refs.dataForm as Form).clearValidate()
    })
  }

  private updateData() {
    (this.$refs.dataForm as Form).validate(async(valid) => {
      if (valid) {
        const data = this.tempData

        await data.save({ with: 'manageable' })

        if (Object.keys(data.errors).length > 0) {
          this.printErrors(data.errors)
        } else {
          const index = this.list.findIndex(v => v.id === data.id)
          this.list.splice(index, 1, data)
          this.dialogFormVisible = false
          this.$notify({
            title: 'User Updated',
            message: 'User successfully updated',
            type: 'success',
            duration: 2000
          })
        }
      }
    })
  }

  private async handleDelete(data: User, index: number) {
    await data.destroy()

    if (Object.keys(data.errors).length > 0) {
      this.printErrors(data.errors)
    } else {
      this.$notify({
        title: 'Success',
        message: 'Delete Successfully',
        type: 'success',
        duration: 2000
      })
      this.list.splice(index, 1)
      this.total -= 1
    }
  }

  private printErrors(errors: any) {
    const e: (IValidationError<User> | undefined)[] = Object.values(errors)
    for (const error of e) {
      if (error) {
        this.$notify({
          title: error.title,
          message: error.fullMessage,
          type: 'error',
          duration: 5000
        })
      }
    }
  }

  private handleSelectManageable(manageableId: any) {
    this.tempData.manageable = this.manageableList.find(e => e.id === manageableId)
  }

  private handleDownload() {
    this.downloadLoading = true
    const tHeader = ['timestamp', 'title', 'type', 'importance', 'status']
    const filterVal = ['timestamp', 'title', 'type', 'importance', 'status']
    const data = formatJson(filterVal, this.list)
    exportJson2Excel(tHeader, data, 'table-list')
    this.downloadLoading = false
  }

  private async getManageableSuggestions(queryString: string, cb: any) {
    if (!queryString) cb([])

    const { data }: any = await Buyer
      .where({ name: { prefix: queryString } })
      .all()

    const sellers = await Seller.where({ name: { prefix: queryString } }).all()
    data.push(...sellers.data)

    const wholesalers = await Wholesaler.where({ name: { prefix: queryString } }).all()
    data.push(...wholesalers.data)

    const caretakers = await Caretaker.where({ name: { prefix: queryString } }).all()
    data.push(...caretakers.data)


    const dataFormatted = data.map((e: any) => {
      return { value: e.name, id: e.id}
    })


    cb(dataFormatted)
  }

  private handleSelectFilterManageable(manageable: any) {
    this.listQuery.manageableId = manageable.id
    this.handleFilter()
  }

  private clearManageableFilter() {
    this.listQuery.manageableId = undefined
    this.handleFilter()
  }
}
