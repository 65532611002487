










































































import {Component, Prop, Vue} from 'vue-property-decorator'
import {Form} from 'element-ui'
import {PurchaseInfo, GiftCard, Rate, Seller, Wholesaler, RateCard, Buyer} from '@/models'

@Component({
  name: 'DialogCreatePurchaseInfo'
})
export default class extends Vue {
  @Prop({ default: false }) visible!: boolean

  private purchaseInfo: PurchaseInfo = new PurchaseInfo({
    buyer: new Buyer({id: ''}),
    wholesaler: new Wholesaler({id: ''}),
    seller: new Seller({id: ''}),
    giftCard: new GiftCard({id: ''}),
    rate: new Rate({
      id: '',
      requirementOperator: 'lt',
      amount: 0,
      rateCard: new RateCard({id: ''})
    }),
    paymentMode: 'prepaid',
    payVat: 'vat_paid',
    offset: 'no_offset',
    vatPayback: 'no_vat_payback'
  })

  private giftCardList: GiftCard[] = []
  private giftCardListLoading = false

  private sellerList: Seller[] = []
  private sellerListLoading = false

  private wholesalerList: Wholesaler[] = []
  private wholesalerListLoading = false

  private rateListLoading = false

  async created() {
    await this.getWholesalerList()
    await this.getSellers()
    await this.getGiftCards()

    await this.handleCreate()
  }

  get loading() {
    return this.sellerListLoading || this.wholesalerListLoading || this.rateListLoading || this.giftCardListLoading
  }

  private async getWholesalerList() {
    this.wholesalerListLoading = true
    const {data} = await Wholesaler.all()

    this.wholesalerList = data
    this.purchaseInfo.wholesaler = data[0].dup()
    this.wholesalerListLoading = false
  }

  private async getSellers() {
    this.sellerListLoading = true
    const {data} = await Seller
        .per(999)
        .page(1)
        .all()

    this.sellerList = data
    this.purchaseInfo.seller = data[0].dup()
    this.sellerListLoading = false
  }

  private async getGiftCards() {
    this.giftCardListLoading = true
    const {data} = await GiftCard
        .per(999)
        .page(1)
        .all()

    this.giftCardList = data
    this.purchaseInfo.giftCard = data[0].dup()
    this.giftCardListLoading = false
  }

  private async getRateCards() {
    const {data} =
        await RateCard
            .includes(['rates'])
            .page(1)
            .per(999)
            .order({createdAt: 'desc'})
            .all()

    this.purchaseInfo.rate.rateCard = data.length > 0 ? data[0] : new RateCard({id: ''})
  }

  private async handleCreate() {
    this.setTempData()
    await this.getRateCards()
    this.$nextTick(() => {
      (this.$refs.dataForm as Form).clearValidate()
    })
  }

  private handleSelectSeller(sellerId: string): void {
    const seller = this.sellerList.find((seller: Seller) => seller.id === sellerId)
    this.purchaseInfo.seller = seller!.dup()
    this.getRateCards()
  }

  private async createOrUpdate() {
    (this.$refs.dataForm as Form).validate(async (valid: any) => {
      if (valid) {
        await this.saveRate()

        await this.purchaseInfo.save({with: ['giftCard.id', 'seller.id', 'wholesaler.id', 'rate.id']})

        this.$notify({
          title: 'Purchase Info created',
          message: 'Purchase Info successfully created',
          type: 'success',
          duration: 2000
        })
        this.$emit('close')
      }
    })
  }

  private async saveRate(): Promise<void> {
    let rate_ = this.purchaseInfo.rate

    await rate_.save({with: 'rateCard.id'})
    this.purchaseInfo.rate = rate_.dup()
  }

  setTempData() {
    this.purchaseInfo = new PurchaseInfo({
      buyer: new Buyer({id: ''}),
      wholesaler: this.wholesalerList[0]!.dup(),
      giftCard: this.giftCardList[0]!.dup(),
      seller: this.sellerList[0]!.dup(),
      rate: new Rate({
        id: '',
        requirementOperator: 'lt_or_eq',
        requirementValue: null,
        amount: 0,
        rateCard: new RateCard({id: ''})
      }),
      paymentMode: 'prepaid',
      payVat: 'vat_paid',
      offset: 'no_offset',
      vatPayback: 'no_vat_payback',
      apiEnabled: false
    })
  }
}
